import React from 'react'
import { Tabs } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'
import { usePostHog } from 'posthog-js/react'

import { CATEGORIES } from '@/constants'
import { useTrackedMIStore } from '../_hook'
import s from './_style.module.css'

export const CategoryTabs = () => {
  const { c2Id, setFilters } = useTrackedMIStore()
  const posthog = usePostHog()

  useDidUpdate(() => {
    posthog?.capture('Main category changed', {
      c2Id,
      category: CATEGORIES.find((c) => c.c2_id === c2Id)?.label,
    })
    setFilters((state) => {
      state.filter_ids = []
    })
  }, [c2Id])

  return (
    <div className="max-w-layout mt-4">
      <Tabs
        color="gray"
        orientation="horizontal"
        value={c2Id}
        onChange={(tab) => {
          if (!tab) return

          setFilters((state) => {
            state.c2Id = tab
          })
        }}
        styles={{
          root: { '--tabs-list-border-style:': '4px' },
        }}
        classNames={{
          tab: s.button,
        }}
      >
        <Tabs.List>
          {CATEGORIES.map((item) => {
            return (
              <Tabs.Tab key={item.c2_id} value={item.c2_id} className="capitalize">
                {item.label.replaceAll('-', ' ')}
              </Tabs.Tab>
            )
          })}
        </Tabs.List>
      </Tabs>
    </div>
  )
}
