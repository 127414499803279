import React, { useEffect, useRef, useState } from 'react'
import { BellIcon } from '@heroicons/react/solid'
import MagicBell, { FloatingNotificationInbox } from '@magicbell/magicbell-react'
import { Modal } from '@mantine/core'
import * as Sentry from '@sentry/react'
import { Widget } from '@typeform/embed-react'
import dayjs from 'dayjs'
import { atom } from 'jotai'
import { usePostHog } from 'posthog-js/react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { ErrorFallback } from '@/components/ErrorFallback'
import { RequireAuth } from '@/components/RequireAuth'
import { ScrollToTop } from '@/components/ScrollToTop'
import { IS_DEV } from '@/constants'
import { LocationHistoryProvider } from '@/contexts/historyContext'
import { useAuth } from '@/hooks/useAuth'
import { notifyError } from '@/util/error'
import { trpc } from '@/util/trpc'
import LogoIcon from './_components/Logo'
import { NavBar } from './_components/NavBar'
import { NewReleaseModal } from './_components/NewReleaseModal'
import { ProfileMenu } from './_components/ProfileMenu'

export const bannerAtom = atom(true)

const Layout: React.FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  const posthog = usePostHog()
  const { user, isLoggedIn } = useAuth()
  const { mutate } = trpc.user.updateCurrentUser.useMutation({
    onError: notifyError,
  })
  const location = useLocation()
  const navigate = useNavigate()
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (location.pathname.startsWith('/home')) {
      navigate('/')
    }
  }, [location.pathname, navigate])

  //   const routeMap = {
  //     'consumer-topics': LockedComponent.CONSUMER_INSIGHTS,
  //     'market-observations': LockedComponent.MARKET_INSIGHTS,
  //     'product-insights': LockedComponent.PRODUCT_INSIGHTS,
  //     reviews: LockedComponent.REVIEW_INSIGHTS,
  //   }

  //   const current = location.pathname.match('/home/(.*)/')?.[1] ?? ''

  //   const lock = user?.company?.blockAccess && user?.lockedComponents?.includes(routeMap[current])
  const [opened, setOpened] = useState(true)
  const showTypeform =
    isLoggedIn &&
    (!user?.lastTypeformShown || dayjs().diff(dayjs(user?.lastTypeformShown), 'month') > 0) &&
    opened

  const email = user?.email ?? ''

  const theme = {
    icon: { borderColor: '#155EEF', width: '36px' },
    banner: {
      fontSize: '14px',
      backgroundColor: '#F9FAFB',
      textColor: '#3A424D',
      backgroundOpacity: 1,
    },
    unseenBadge: { backgroundColor: '#F80808' },
    header: {
      fontSize: '15px',
      fontWeight: 500,
      backgroundColor: '#FFFFFF',
      textColor: '#155EEF',
      borderRadius: '16px',
    },
    footer: {
      fontSize: '15px',
      backgroundColor: '#FFFFFF',
      textColor: '#155EEF',
      borderRadius: '16px',
    },
    notification: {
      default: {
        fontSize: '14px',
        textColor: '#3A424D',
        borderRadius: '16px',
        backgroundColor: '#FFFFFF',
        hover: { backgroundColor: '#D1E0FF' },
        state: { color: 'transparent' },
        margin: '8px',
      },
      unseen: {
        textColor: '#3A424D',
        backgroundColor: '#F9FAFB',
        hover: { backgroundColor: '#D1E0FF' },
        state: { color: '#155EEF' },
      },
      unread: {
        textColor: '#3A424D',
        backgroundColor: '#F9FAFB',
        hover: { backgroundColor: '#D1E0FF' },
        state: { color: '#155EEF' },
      },
    },
  }
  // const [showBanner, setShowBanner] = useAtom(bannerAtom)
  return (
    <RequireAuth>
      <NewReleaseModal />
      <Modal
        opened={showTypeform}
        onClose={() => {
          mutate({
            lastTypeformShown: new Date(),
          })

          setOpened(false)
        }}
        size="xl"
        centered
      >
        <Widget
          id="sIkO8Poz"
          style={{ width: '100%', height: '60vh' }}
          className="m-auto"
          onSubmit={(event) => {
            posthog?.capture(`Typeform Response`, event)
          }}
        />
      </Modal>
      {
        <div className=" bg-accent-600 py-2 text-xs">
          <div className="max-w-layout mx-auto flex justify-center px-10 font-medium text-white">
            {/* <div></div> */}
            <p>
              Accelerate your research with the new web research assistant!{' '}
              <a
                href={IS_DEV ? 'https://search-dev.eyva.ai/' : 'https://search.eyva.ai/'}
                className="underline underline-offset-2"
              >
                Click here
              </a>{' '}
              to try it out 🔥
            </p>
            {/* <button
              onClick={() => {
                setShowBanner(false)
              }}
            >
              <XIcon size={15} />
            </button> */}
          </div>
        </div>
      }
      <div className="border-b border-gray-200 p-4">
        <div className="max-w-layout mx-auto flex items-center justify-between">
          <a href="/">
            <LogoIcon className="mt-2 h-7" />
          </a>
          <NavBar />

          <div className="flex items-center space-x-6">
            {email.length > 0 && (
              <MagicBell
                apiKey="cbe2e3fdeb52993576b1378700b1ddd973a388a7"
                userEmail={email}
                theme={theme}
                BellIcon={
                  <BellIcon className=" rounded-md p-2 text-primary-500 hover:bg-gray-200" />
                }
                locale="en"
              >
                {(props) => (
                  <FloatingNotificationInbox
                    width={400}
                    closeOnNotificationClick={false}
                    notificationPreferencesEnabled={false}
                    height={500}
                    {...props}
                  />
                )}
              </MagicBell>
            )}
            <div className="flex items-center space-x-2">
              <ProfileMenu />
              {user?.isFree && (
                <div className="rounded-md bg-accent-600 px-1.5 py-1 text-xxs font-medium text-white">
                  FREE
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <main className="h-[calc(100vh-100px)] overflow-y-auto text-sm" id="main" ref={ref}>
        <ScrollToTop />
        {/* {children ?? <Outlet />} */}
        {Sentry.withErrorBoundary(() => children ?? <Outlet />, {
          fallback: <ErrorFallback title="Error" />,
        })({})}
      </main>
    </RequireAuth>
  )
}

const LayoutProvider: React.FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <LocationHistoryProvider>
      <Layout>{children}</Layout>
    </LocationHistoryProvider>
  )
}

export default Sentry.withErrorBoundary(LayoutProvider, {
  fallback: <ErrorFallback title="Error" />,
})
