import React, { forwardRef, useEffect, useState } from 'react'
import { Button, Checkbox, Modal } from '@mantine/core'
import { atom, useAtom, useSetAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { xorBy } from 'lodash'
import { To, useNavigate } from 'react-router-dom'
import { getUntrackedObject } from 'react-tracked'

import { FilterType, useTrackedMIStore } from '../_hook'
import { FilterChip } from './Filters'

const showSaveFilterPromptAtom = atomWithStorage<boolean>('showSaveFilterPromptAtom', false)

const saveFilterPromptAtom = atom<{ opened: boolean; to: To }>({
  opened: false,
  to: '#',
})

export const SaveFilterModal = () => {
  const [showSaveFilterPrompt, setShowSaveFilterPrompt] = useAtom(showSaveFilterPromptAtom)
  const [saveFilterPrompt, setSaveFilterPrompt] = useAtom(saveFilterPromptAtom)
  const navigate = useNavigate()
  const { filter_ids, setFilters } = useTrackedMIStore()
  const [filterIds, setFilterIds] = useState<FilterType[]>([])

  useEffect(() => {
    setFilterIds(getUntrackedObject(filter_ids) ?? [])
  }, [filter_ids])

  return (
    <Modal
      opened={saveFilterPrompt.opened}
      onClose={() => {
        setSaveFilterPrompt((prev) => ({ ...prev, opened: false }))
      }}
      size="xl"
      centered
      padding={40}
    >
      <div className="flex min-h-[350px] flex-col justify-center space-y-10 text-center">
        <h1 className="text-lg font-medium">
          Do you want to keep your search and filter settings?
        </h1>
        <div className="-m-1 mx-auto flex flex-wrap items-center">
          {filterIds.map((d) => (
            <FilterChip
              key={d.id}
              onClose={() => {
                setFilterIds(xorBy(filterIds, [d], (o) => o.id))
              }}
            >
              {d.name}
            </FilterChip>
          ))}
        </div>
        <p className="text-sm text-primary-500">
          Do you want to apply these settings to the <br /> new section or do you want to start a
          new search?
        </p>
        <div className="flex justify-center space-x-2">
          <Button
            variant="default"
            onClick={() => {
              setFilters((state) => {
                state.filter_ids = []
              })
              navigate(saveFilterPrompt.to + location.search)
              setSaveFilterPrompt((prev) => ({ ...prev, opened: false }))
            }}
          >
            No, I want brand new search
          </Button>
          <Button
            onClick={() => {
              setFilters((state) => {
                state.filter_ids = filterIds
              })
              navigate(saveFilterPrompt.to + location.search)
              setSaveFilterPrompt((prev) => ({ ...prev, opened: false }))
            }}
          >
            I want to use the same settings
          </Button>
        </div>
        <Checkbox
          size="xs"
          checked={showSaveFilterPrompt}
          onChange={(event) => setShowSaveFilterPrompt(event.currentTarget.checked)}
          className="mx-auto"
          label={<p className="text-xs text-primary-500">Don&apos;t ask again</p>}
        />
      </div>
    </Modal>
  )
}

export const SaveFilterPrompt = forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<{ to: To; className?: string; onClick?: () => void }>
>(({ to, className, children, onClick }, ref) => {
  const setSaveFilterPrompt = useSetAtom(saveFilterPromptAtom)
  const [showSaveFilterPrompt, setShowSaveFilterPrompt] = useAtom(showSaveFilterPromptAtom)
  const { filter_ids } = useTrackedMIStore()
  const navigate = useNavigate()

  return (
    <button
      ref={ref}
      className={className}
      onClick={() => {
        onClick?.()
        if (filter_ids.length === 0 || showSaveFilterPrompt) {
          navigate(to + location.search)
          return
        }
        setSaveFilterPrompt((prev) => ({ to, opened: true }))
      }}
    >
      {children}
    </button>
  )
})

SaveFilterPrompt.displayName = 'SaveFilterPrompt'
