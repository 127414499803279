import { FunctionComponent } from 'react'
import { LogoutIcon, UserIcon } from '@heroicons/react/solid'
import { Menu } from '@mantine/core'
import { usePostHog } from 'posthog-js/react'
import { Link, useNavigate } from 'react-router-dom'

import { Loader } from '@/components/Loader'
import { useAuth } from '@/hooks/useAuth'
import { trpc } from '@/util/trpc'

declare const window: any

export const ProfileMenu: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate()
  const posthog = usePostHog()

  const { mutate, isLoading } = trpc.user.logoutSB.useMutation({
    onSuccess: () => {
      posthog?.reset()
      window.Intercom('shutdown')
      navigate('/login')
    },
  })
  const { user } = useAuth()
  const image = user?.image || ''
  return (
    <div className="inline-block text-left">
      <Menu shadow="md" width={200} position="bottom-end" offset={14}>
        <Menu.Target>
          {image ? (
            <img
              className="mr-2 h-10 w-10 cursor-pointer rounded-full object-cover"
              src={image}
              alt=""
            />
          ) : (
            <div className="m-auto cursor-pointer rounded-full bg-gray-100 px-2 py-2.5">
              <UserIcon className="-mt-1 h-5 w-5 text-primary-300" />
            </div>
          )}
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            leftSection={<UserIcon className="h-4 w-4 text-primary-500" />}
            component={Link}
            className="text-sm font-medium"
            to="/userProfile"
          >
            Profile settings
          </Menu.Item>
          <Menu.Item
            leftSection={
              isLoading ? (
                <Loader className="h-4 w-4 border-2" />
              ) : (
                <LogoutIcon className="h-4 w-4 text-primary-500" />
              )
            }
            onClick={() => {
              mutate()
            }}
            className="text-sm font-medium"
          >
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}
