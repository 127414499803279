import { useEffect } from 'react'
import { createSearchParams, Navigate, useLocation } from 'react-router-dom'

import { useAuth } from '@/hooks/useAuth'
import userRoles from '@/util/userRoles'
import { Loader } from '../Loader'

const statuses = {
  UNVERIFIED: 'UNVERIFIED',
  VERIFIED: 'VERIFIED',
  PROFILE_COMPLETED: 'PROFILE_COMPLETED',
  DOMAIN_COMPLETED: 'DOMAIN_COMPLETED',
  INDUSTRY_COMPLETED: 'INDUSTRY_COMPLETED',
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
}
declare const window: any

export const RequireAuth = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation()
  const { user, isError, isProfileLoading } = useAuth()

  useEffect(() => {
    window.Intercom('update')
    console.log('Intercom update')
  }, [location.pathname])

  if (isProfileLoading)
    return (
      <div className="flex h-screen w-screen items-center justify-center bg-white">
        <Loader className="h-36 w-36 border-accent-600" />
      </div>
    )
  if (isError)
    return (
      <Navigate
        to={{
          pathname: '/login',
          search: createSearchParams({
            from: location.pathname + location.search + location.hash,
          }).toString(),
        }}
        state={{ from: location }}
      />
    )
  if (user?.status === statuses.VERIFIED && location.pathname !== '/register/create-profile') {
    return <Navigate to="/register/create-profile" state={{ from: location }} />
  }

  if (
    user?.status === statuses.PROFILE_COMPLETED &&
    location.pathname !== '/register/company' &&
    user?.role === userRoles.superAdmin
  ) {
    return <Navigate to={'/register/company'} state={{ from: location }} />
  }

  return <>{children}</>
}
