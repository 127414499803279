import React from 'react'

import Layout from './(home)/_layout'

export default function PageNotFound() {
  return (
    <Layout>
      <div className="max-w-layout mt-14 space-y-5 pb-40">404 - Page Not Found</div>
    </Layout>
  )
}
