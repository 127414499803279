import { isValidElement, useEffect } from 'react'
import { Button } from '@mantine/core'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { useI18nContext } from '@/i18n/i18n-react'
import { classNames } from '@/util/classNames'
import { I18nKeys } from '@/util/i18nKey'

export const ErrorFallback: React.FunctionComponent<
  React.PropsWithChildren<{
    title: { i18nKey: I18nKeys; args?: any } | React.ReactElement | string
    className?: string
  }>
> = ({ title, className }) => {
  const navigate = useNavigate()
  const { LL } = useI18nContext()
  const t = !(isValidElement(title) || typeof title === 'string')
    ? get(LL, (title as any).i18nKey)((title as any).args)
    : title
  return (
    <div className={classNames('max-w-layout rounded-md bg-white', className)}>
      <div className="border-b border-gray-100 p-4 font-semibold">{t}</div>
      <div className="m-auto -mt-5 flex h-80 max-w-[382px] flex-col items-center justify-center text-center">
        <img src="/icons/warning.svg" alt="" className="size-12 " />
        <div className="mt-2 space-y-1">
          <p className="text-base font-medium text-gray-800">Something went wrong...</p>
          <p className="text-sm font-medium text-gray-600">
            We had some trouble loading this feature. Please refresh the page to try again or get in
            touch if the problem sticks around!
          </p>
        </div>
        <Button
          w={'100%'}
          className="mt-5"
          onClick={() => {
            navigate(0)
          }}
        >
          Refresh page
        </Button>
      </div>
    </div>
  )
}

export function setWithExpiry(key, value, ttl) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export function getWithExpiry(key) {
  const itemString = window.localStorage.getItem(key)
  if (!itemString) return null

  const item = JSON.parse(itemString)
  const isExpired = new Date().getTime() > item.expiry

  if (isExpired) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}

export const ErrorFallbackChunk = ({ error }) => {
  useEffect(() => {
    const chunkFailedMessage = /Failed to load module script:/
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000)
        window.location.reload()
      }
    }
  }, [error])

  return (
    <div className={classNames('max-w-layout rounded-md bg-white')}>
      <div className="border-b border-gray-100 p-4 font-semibold">Error</div>
      <div className="m-auto -mt-5 flex h-80 max-w-[382px] flex-col items-center justify-center text-center">
        <img src="/icons/warning.svg" alt="" className="size-12 " />
        <div className="mt-2 space-y-1">
          <p className="text-base font-medium text-gray-800">Something went wrong...</p>
          <p className="text-sm font-medium text-gray-600">
            We had some trouble loading this feature. Please refresh the page to try again or get in
            touch if the problem sticks around!
          </p>
        </div>
        <button
          className="mt-5"
          onClick={() => {
            window.location.reload()
          }}
        >
          Refresh page
        </button>
      </div>
    </div>
  )
}
