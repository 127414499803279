export const getCurrency = {
  de: { locale: 'de-DE', code: 'EUR', symbol: '\u20ac', name: 'Euro', country: 'Germany' },
  us: {
    locale: 'en-US',
    code: 'USD',
    symbol: '$',
    name: 'United States dollar',
    country: 'USA',
  },
  uk: {
    locale: 'en-GB',
    code: 'GBP',
    symbol: '\u00a3',
    name: 'Pound',
    country: 'UK',
  },
}

export type Countries = keyof typeof getCurrency
