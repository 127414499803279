import { Modal } from '@mantine/core'
import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

const RELEASE_DATE = `2023-12-06`

const modalStateAtom = atomWithStorage(`showNewRelease-${RELEASE_DATE}`, true)

export const NewReleaseModal = () => {
  const [opened, setOpened] = useAtom(modalStateAtom)
  return (
    <Modal
      opened={opened && dayjs().diff(dayjs(RELEASE_DATE), 'days') < 10} // show for 10 days
      onClose={() => setOpened(false)}
      title={
        <h2 className="text-base font-semibold">
          Exciting News: Enhanced Features and Updated Consumer Insights in eyva
        </h2>
      }
      withinPortal={false}
      size={'700px'}
      centered
      padding={25}
    >
      <div className="space-y-4 text-sm font-medium text-gray-700">
        <p>
          We&apos;re thrilled to share major updates: the first phase of our Insights and
          Collaboration Engine (ICE), Review Insights launch, Excel export in product comparison,
          and updated Consumer Insights. Check out our video for a quick overview.
        </p>

        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video width={'100%'} height="auto" controls>
          <source
            src="https://eyva-reports.s3.eu-central-1.amazonaws.com/Update+20231205.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </Modal>
  )
}
