import * as React from 'react'

function WholeWord(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 11h1v2h14v-2h1v3H0v-3z" />
      <path d="M6.84 11h-.88v-.86h-.022c-.383.66-.947.989-1.692.989-.548 0-.977-.145-1.289-.435-.308-.29-.462-.675-.462-1.155 0-1.028.605-1.626 1.816-1.794l1.649-.23c0-.935-.378-1.403-1.134-1.403-.662 0-1.26.226-1.794.677v-.902c.541-.344 1.164-.516 1.87-.516 1.292 0 1.938.684 1.938 2.052V11zm-.88-2.782L4.633 8.4c-.408.058-.716.16-.924.307-.208.143-.311.399-.311.768 0 .268.095.488.284.66.194.168.45.253.768.253a1.41 1.41 0 001.08-.457c.286-.308.43-.696.43-1.165v-.548zM9.348 10.205h-.022V11h-.88V2.857h.88v3.61h.021c.434-.73 1.068-1.096 1.902-1.096.705 0 1.257.247 1.654.741.401.49.602 1.15.602 1.977 0 .92-.224 1.658-.672 2.213-.447.551-1.06.827-1.837.827-.726 0-1.276-.308-1.649-.924zm-.022-2.218v.768c0 .455.147.841.44 1.16.298.315.674.473 1.128.473.534 0 .951-.204 1.252-.613.304-.408.456-.975.456-1.702 0-.613-.141-1.092-.424-1.44-.283-.347-.666-.52-1.15-.52-.511 0-.923.178-1.235.536-.311.355-.467.8-.467 1.338z" />
    </svg>
  )
}

export default React.memo(WholeWord)
