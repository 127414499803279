import { CheckIcon, MinusSmIcon } from '@heroicons/react/outline'
import {
  Button,
  Checkbox,
  createTheme,
  Modal,
  NumberInput,
  Rating,
  ScrollArea,
  Switch,
  Tooltip,
} from '@mantine/core'

export const theme = createTheme({
  /** Put your mantine theme override here */
  fontFamily: 'Poppins, sans-serif',
  //   colorScheme: 'light',
  colors: {
    blue: [
      '#eff6ff',
      '#dbeafe',
      '#bfdbfe',
      '#93c5fd',
      '#60a5fa',
      '#3b82f6',
      '#2563eb',
      '#1d4ed8',
      '#1e40af',
      '#1e3a8a',
    ],
    gray: [
      '#f9fafb',
      '#f3f4f6',
      '#E4E6EA',
      '#d1d5db',
      '#9ca3af',
      '#6b7280',
      '#4b5563',
      '#374151',
      '#1f2937',
      '#111827',
    ],
  },
  radius: {
    md: '6px',
    lg: '8px',
  },
  primaryColor: 'blue',
  defaultRadius: 'md',
  components: {
    Rating: Rating.extend({ defaultProps: { fractions: 3 } }),
    Overlay: Modal.extend({
      defaultProps: {
        zIndex: 10,
      },
    }),
    LoadingOverlay: Modal.extend({
      defaultProps: {
        zIndex: 10,
      },
    }),
    Switch: Switch.extend({
      styles(theme, props, ctx) {
        return {
          root: {
            '--switch-width-sm': 'calc(2rem * var(--mantine-scale))',
          },
        }
      },
    }),
    Modal: Modal.extend({
      defaultProps: {
        overlayProps: {
          color: '#E4E6EA',
          backgroundOpacity: 0.75,
        },
      },
    }),
    ScrollArea: ScrollArea.extend({
      classNames(theme, props, ctx) {
        return {
          thumb: '!bg-gray-300 hover:!bg-gray-400',
        }
      },
    }),
    NumberInput: NumberInput.extend({
      classNames: (theme, props) => {
        return {
          control: 'border !border-gray-200',
        }
      },
    }),
    Tooltip: Tooltip.extend({
      defaultProps: {
        withArrow: true,
      },
      styles: {
        tooltip: {
          whiteSpace: 'pre-line',
          fontSize: '12px',
        },
      },
    }),
    Checkbox: Checkbox.extend({
      defaultProps: {
        radius: 'sm',
        icon: ({ indeterminate, ...others }) =>
          indeterminate ? <MinusSmIcon {...others} /> : <CheckIcon {...others} />,
      },
      classNames: (theme, props) => {
        return {
          icon: '!w-[95%] !text-accent-600',
        }
      },
    }),
    Button: Button.extend({
      classNames: (theme, props) => {
        // if (props.loading) {
        //   return {
        //     loader: s.loader,
        //   }
        // }
        if (props.variant === 'subtle') {
          return {
            root: '!font-medium p-0 h-auto !bg-transparent hover:bg-transparent',
          }
        }

        if (props.variant === 'default') {
          return {
            root: '!font-medium border !border-gray-200',
          }
        }

        if (props.disabled) {
          return { root: '!font-medium !bg-white !border !border-gray-300' }
        }

        return { root: '!font-medium ' }
      },
    }),
  },
})
