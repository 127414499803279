import React, { useEffect, useState } from 'react'

import { useAuth } from '@/hooks/useAuth'
import TypesafeI18n from '@/i18n/i18n-react'
import { loadLocaleAsync } from '@/i18n/i18n-util.async'

export const I18n: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { user } = useAuth()
  const [localesLoaded, setLocalesLoaded] = useState<{ en: boolean; de: boolean }>({
    en: false,
    de: false,
  })
  const language = user?.language ?? 'en'
  useEffect(() => {
    if (localesLoaded[language]) return
    loadLocaleAsync(language).then(() => setLocalesLoaded({ ...localesLoaded, [language]: true }))
  }, [localesLoaded, language])

  if (!localesLoaded[language]) {
    return null
  }
  return <TypesafeI18n locale={language}>{children}</TypesafeI18n>
}
