import { capitalCase } from 'change-case'
import { sortBy } from 'lodash'

// douglas_de => Douglas
export const prepEtailerName = (value: string): string => {
  return capitalCase(value?.replace(/_de|_com|_uk/g, '') ?? '')
}
const priorityList = [
  'douglas',
  'flaconi',
  'sephora',
  'dm',
  'rossmann',
  'net_a_porter',
  'notino',
  'ulta',
  'niche_beauty',
  'amazon',
].map(prepEtailerName)

export function getEtailersPriority(domain = '') {
  for (let i = 0; i < priorityList.length; i++) {
    if (prepEtailerName(domain).startsWith(priorityList[i])) {
      return i
    }
  }
  return priorityList.length // If domain is not in priority list, move it to the end
}

export const prepEtailerUrlList = (urls: string[]) => {
  const prepEtailer = (val: string) => capitalCase(val?.replace(/.+\/\/|www.|\..+/g, '') ?? '')
  return sortBy(
    urls
      .filter((url) => url !== 'NOT_AVAILABLE')
      .map((url) => ({
        etailer: prepEtailer(url),
        url: url,
      })),

    (item) => getEtailersPriority(item.etailer)
  )
}
