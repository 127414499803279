import * as React from 'react'

function Compare(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_2743_21194"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="2"
        width="21"
        height="17"
      >
        <path d="M0.520996 2.80225H20.521V18.4398H0.520996V2.80225Z" fill="white" />
      </mask>
      <g mask="url(#mask0_2743_21194)">
        <path
          d="M13.1549 13.3809L16.5137 7.56009C16.5453 7.56604 16.5776 7.56941 16.6108 7.56941C16.6437 7.56941 16.676 7.56604 16.7073 7.56009L20.0664 13.3809H13.1549ZM0.975859 9.64335L4.33491 3.82229C4.36623 3.82824 4.39859 3.83161 4.43147 3.83161C4.46461 3.83161 4.49697 3.82824 4.5283 3.82229L7.88735 9.64335H0.975859ZM20.521 13.553C20.522 13.5225 20.5148 13.4917 20.499 13.4642L16.9965 7.3944C17.0765 7.30379 17.1254 7.18496 17.1254 7.05448C17.1254 6.76997 16.895 6.5393 16.6108 6.5393C16.4311 6.5393 16.2729 6.63172 16.1808 6.77126L11.2681 5.20759C11.27 5.18688 11.2712 5.16591 11.2712 5.14468C11.2712 4.7302 10.9355 4.39417 10.521 4.39417C10.2458 4.39417 10.0056 4.54303 9.87508 4.76437L4.93009 3.19086C4.87391 2.96745 4.67224 2.80176 4.43147 2.80176C4.14722 2.80176 3.91681 3.03243 3.91681 3.31668C3.91681 3.44716 3.96574 3.56625 4.04573 3.65686L0.54326 9.72645C0.527468 9.75389 0.520219 9.7847 0.521255 9.81551H0.520996C0.520996 11.3025 2.27184 12.5082 4.43147 12.5082C6.59136 12.5082 8.34221 11.3025 8.34221 9.81551H8.34195C8.34299 9.7847 8.33574 9.75389 8.3202 9.72645L4.81747 3.65686C4.85061 3.61932 4.87831 3.5766 4.89954 3.53078L9.77411 5.08177C9.7723 5.10274 9.77074 5.12371 9.77074 5.14468C9.77074 5.38829 9.88724 5.6042 10.0669 5.74141V17.5308H7.75557V18.4395H13.2867V17.5308H10.9753V5.74141C11.0525 5.68239 11.1175 5.60912 11.1669 5.52524L16.0979 7.09461C16.1067 7.2093 16.1531 7.31311 16.2248 7.3944L12.722 13.4642C12.7063 13.4917 12.6993 13.5225 12.7003 13.553H12.7C12.7 15.0403 14.4509 16.246 16.6108 16.246C18.7704 16.246 20.5213 15.0403 20.5213 13.553H20.521Z"
          fill="black"
        />
      </g>
    </svg>
  )
}

const CompareIcon = React.memo(Compare)
export default CompareIcon
