import React from 'react'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Tooltip, TooltipProps } from '@mantine/core'

// export const ReactTooltip: React.FunctionComponent<React.PropsWithChildren<ReactTooltipProps>> = ({
//   id,
//   children,
//   ...rest
// }) => {
//   return (
//     <>
//       <ReactTooltipDefault
//         id={id}
//         className="max-w-sm text-sm font-medium"
//         type="dark"
//         backgroundColor="#D1E0FF"
//         borderColor="#0E4CC3"
//         textColor="black"
//         border
//         place="right"
//         {...rest}
//       >
//         {children}
//       </ReactTooltipDefault>
//     </>
//   )
// }

export const InfoTooltip = ({
  children,
  header,
  ...rest
}: Omit<TooltipProps, 'label'> & { header?: string }) => {
  return (
    <Tooltip
      w={230}
      {...rest}
      label={
        <div className="space-y-1">
          {header && <p className="font-semibold">{header}</p>}
          {children}
        </div>
      }
      classNames={{ tooltip: 'max-w-sm' }}
    >
      <span>
        <InformationCircleIcon className="size-4 text-primary-900 " />
      </span>
    </Tooltip>
  )
}

export const ShouldShowTooltip = ({
  children,
  show,
  ...rest
}: { children: React.ReactNode; show: boolean } & TooltipProps) => {
  return show ? <Tooltip {...rest}>{children}</Tooltip> : <>{children}</>
}
