import { useCallback, useMemo } from 'react'
import { notifications } from '@mantine/notifications'
import { uniqBy, xorBy } from 'lodash'
import { CheckIcon } from 'lucide-react'
import { createTrackedSelector } from 'react-tracked'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { createSelectors } from '@/util/create-selectors'
import { trpc } from '@/util/trpc'
import { createPersistentStorage } from '@/util/zustand-middleware'
import { useMIVars } from '../market-insights/_hook'

export type Product = { id: number; url?: string }

interface State {
  products: Product[]
}

interface Actions {
  setProducts: (f: Product[]) => void
  toggleProducts: (f: Product[]) => void
  addProducts: (f: Product[]) => void
  resetProducts: () => void
}

export const usePIStoreBase = create<State & Actions>()(
  persist(
    immer((set, get) => ({
      products: [],

      setProducts(f) {
        set((state) => {
          state.products = f
        })
      },

      toggleProducts(f) {
        set((state) => {
          state.products = xorBy(state.products, f, (o) => o.id)
        })
      },

      addProducts(f) {
        set((state) => {
          state.products = uniqBy([...state.products, ...f], (o) => o.id)
        })
      },

      resetProducts: () => set({ products: [] }),
    })),
    {
      name: 'pi',
      storage: createPersistentStorage(),
      partialize: (state) =>
        Object.fromEntries(Object.entries(state).filter(([key]) => !['vars'].includes(key))),
    }
  )
)

export const usePIStore = createSelectors(usePIStoreBase)

export const useTrackedPIStore = createTrackedSelector(usePIStoreBase)

export const useSelectProduct = ({ p_c_id }: { p_c_id: number }) => {
  const { toggleProducts, products } = useTrackedPIStore()
  const { vars } = useMIVars()

  const isProductSelected = useMemo(
    () => products.findIndex((p) => p.id === p_c_id) > -1,
    [p_c_id, products]
  )
  const { mutate } = trpc.product_insights.enqueueClaimsImages.useMutation()
  const selectProduct = useCallback(() => {
    if (!isProductSelected && products.length > 0) {
      notifications.show({
        title: 'Success!',
        message: 'The product has been added to the comparison list.',
        color: 'green',
        autoClose: 2000,
        icon: <CheckIcon className="h-4 w-4" />,
      })
    }
    toggleProducts([{ id: p_c_id }])
    mutate({ p_c_ids: [p_c_id], geo: vars.geo })
  }, [isProductSelected, mutate, p_c_id, products.length, toggleProducts, vars.geo])

  return { isProductSelected, selectProduct }
}
