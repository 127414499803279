import { useEffect, useRef, useState } from 'react'
import { SparklesIcon, XIcon } from '@heroicons/react/solid'
import { Button, Modal, TextInput, Tooltip } from '@mantine/core'
import * as Sentry from '@sentry/react'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { groupBy, uniq, xorBy } from 'lodash'
import { usePostHog } from 'posthog-js/react'
import { MagicWand01, SearchMd } from 'untitled-icons'

import { ErrorFallback } from '@/components/ErrorFallback'
import { Loader, OscillatingLoading } from '@/components/Loader'
import { classNames } from '@/util/classNames'
import { formatNumberCompact } from '@/util/formatNumber'
import { trpc } from '@/util/trpc'
import { FILTERS_MAP, FilterType, useTrackedFilterStore, useTrackedMIStore } from '../_hook'

export const FILTERS_EMOJI = {
  'Categories & Product Types': '🔸',
  Etailers: '©',
  'Price Range': '',
  ingredients: '🧪',
  'For Whom': '',
  brands: '©',
  effects: '🌟',
  attributes: '🌿',
  concerns: '😔',
  certificates: '',
  sizes: '',
  ratings: '',
} as const

export const ModalContent = Sentry.withErrorBoundary(
  ({ opened, setOpened }: { opened: boolean; setOpened: (val: boolean) => void }) => {
    const posthog = usePostHog()
    const { setFilters } = useTrackedMIStore()
    const vars = useTrackedFilterStore()
    const [search, setSearch] = useState('')
    const [enabled, setEnabled] = useState(false)
    const [filterIds, setFilterIds] = useState<FilterType[]>([])
    const [hoveredId, setHoveredId] = useState('')
    const scrollContainerRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      // The ID of the <li> you want to check
      // const targetId = 'target-li-id'
      const targetElement = document.getElementById(hoveredId)

      if (targetElement && scrollContainerRef.current) {
        const scrollContainer = scrollContainerRef.current

        const { top: elementTop, bottom: elementBottom } = targetElement.getBoundingClientRect()
        const { top: containerTop, bottom: containerBottom } =
          scrollContainer.getBoundingClientRect()

        // Check if the <li> is not fully in view within the scroll container
        if (elementTop < containerTop || elementBottom > containerBottom) {
          // Scroll the <li> into view
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }
      }
    }, [hoveredId]) // Empty dependency array means this runs once on mount
    const { data, isLoading } = trpc.ecom_filters.ai_filters.useQuery(
      {
        ...vars,
        q: search,
      },
      {
        enabled,
        keepPreviousData: true,
        select(data) {
          return groupBy(
            data?.map((d) => ({
              id: `${d.id}`,
              type: d.id_type,
              name: d.name,
              reason: d.reason,
            })) ?? [],
            (o) => FILTERS_MAP[o.type]
          )
        },
      }
    )

    const { data: count, isLoading: isCountLoading } = trpc.ecom_filters.productCount.useQuery(
      {
        ...vars,
        filter_ids: filterIds.map(({ id, type }) => ({ id, type })),
      },
      {
        enabled: filterIds.length > 0,
      }
    )

    // const data = useMemo(
    //   () => groupBy(filterIds, (o) => FILTERS_MAP[o.type] || 'default'),
    //   [filterIds]
    // )
    return !enabled ? (
      <div className="mx-auto -mt-10 flex min-h-[500px] max-w-[70%] flex-col justify-center space-y-4 text-center">
        <div className="mx-auto flex items-center justify-center space-x-1 text-center text-xs font-medium text-accent-600">
          <MagicWand01 className="-mt-1 h-4 w-4" />
          <p>AI filters</p>
        </div>
        <h1 className="text-lg font-medium">What are you looking for?</h1>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            setEnabled(true)
          }}
        >
          <TextInput
            value={search}
            onChange={(event) => setSearch(event.currentTarget.value)}
            placeholder="Search..."
            leftSection={<SearchMd className="ml-1 h-4 w-4" />}
          />
        </form>
      </div>
    ) : isLoading ? (
      <div className="mx-auto flex min-h-[500px] flex-col justify-center space-y-4 text-center">
        <div>
          <OscillatingLoading height={250} />
        </div>
        <p className="-mt-10 text-sm text-primary-500">eyva is looking for the best match</p>
      </div>
    ) : (
      <div className="grid grid-cols-2">
        <div
          className="max-h-[750px] min-h-[500px] space-y-4 overflow-y-auto rounded-2xl bg-primary-700 p-6 text-white"
          ref={scrollContainerRef}
        >
          <div className="space-y-6">
            <h1 className="text-lg font-semibold">eyva has identified following options:</h1>

            {uniq(Object.values(FILTERS_MAP)).map((filterType) => {
              const filters = data?.[filterType] ?? []
              if (filters.length > 0)
                return (
                  <div key={filterType} className="space-y-5">
                    <h2 className="font-semibold capitalize">
                      {FILTERS_EMOJI[filterType]} {filterType}
                    </h2>
                    <ul className="custom-disc ml-6 space-y-3 text-sm">
                      {filters.map((d) => {
                        return (
                          <li
                            key={d.id}
                            id={d.id}
                            className={classNames(
                              'py-1 pr-2',
                              hoveredId === d.id && '!-ml-5 rounded-md bg-white pl-5 text-black'
                            )}
                          >
                            <span className="font-semibold">{d.name}:</span> {d.reason}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )
            })}
          </div>
        </div>
        <div className="flex max-h-[750px]  min-h-[500px] flex-col justify-between space-y-4 overflow-y-auto p-5">
          <div className="space-y-4">
            <h1 className="text-lg font-semibold">Best Match!</h1>

            <p className="!mt-2 text-sm text-primary-500">
              Please choose options that best match your needs.
            </p>

            {uniq(Object.values(FILTERS_MAP)).map((filterType) => {
              const filters = data?.[filterType] ?? []
              if (filters.length > 0)
                return (
                  <div key={filterType} className="space-y-2">
                    <h2 className="font-semibold capitalize">{filterType}</h2>
                    <div className="-m-1 flex flex-wrap items-center">
                      {filters.map((d) => {
                        const isSelected = filterIds.some((o) => o.id === d.id)
                        return (
                          <button
                            key={d.id}
                            onMouseEnter={() => setHoveredId(d.id)}
                            onMouseLeave={() => setHoveredId('')}
                            onClick={() => {
                              setFilterIds(xorBy(filterIds, [d], (o) => o.id))
                            }}
                            className={classNames(
                              'm-1 flex items-center space-x-2 rounded-md border border-gray-300 bg-gray-50 px-3 py-2 text-xs',
                              isSelected && 'border-accent-600 bg-accent-600 text-white'
                            )}
                          >
                            {d.name}
                          </button>
                        )
                      })}
                    </div>
                  </div>
                )
            })}
          </div>
          <div className="space-y-2">
            <p className="flex items-center text-sm text-primary-500">
              {filterIds.length > 0 ? (
                <>
                  {isCountLoading ? (
                    <Loader className="mr-2 h-4 w-4 border-2" />
                  ) : (
                    formatNumberCompact(count)
                  )}{' '}
                  products were found on e-tailers with your selection.
                </>
              ) : (
                'Do you want to apply these settings or do you want to try again?'
              )}
            </p>
            <div className="flex space-x-2">
              <Button
                variant="default"
                onClick={() => {
                  setEnabled(false)
                }}
              >
                No, I want to try again
              </Button>
              <Button
                disabled={filterIds.length === 0 || count === 0 || isCountLoading}
                onClick={() => {
                  setFilters((state) => {
                    state.filter_ids = filterIds
                  })
                  posthog?.capture('AI Filters', {
                    search,
                    filterIds,
                    country: vars.geo,
                  })
                  setOpened(false)
                }}
              >
                Apply search settings
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  },
  {
    fallback: <ErrorFallback title="AI Filters" />,
  }
)

const toolTipAtom = atomWithStorage('ai-filter-tooltip', true)

export const AIFilters = () => {
  const [opened, setOpened] = useState(false)

  const [tooltip, setTooltip] = useAtom(toolTipAtom) // initially show tooltip then hide it forever
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false)
        }}
        size={1244}
        centered
        withCloseButton={false}
        className="relative"
        p={0}
      >
        <button className="absolute right-8 top-8" onClick={() => setOpened(false)}>
          <XIcon className="h-5 w-5 text-primary-500" />{' '}
        </button>
        <ModalContent opened={opened} setOpened={setOpened} />
      </Modal>
      <Tooltip
        opened={tooltip || showTooltip}
        w={230}
        classNames={{
          tooltip: '!pointer-events-auto',
        }}
        label={
          <div className="space-y-1">
            <div className="flex justify-between">
              <p className="font-semibold">AI Filters</p>
              {tooltip && (
                <button onClick={() => setTooltip(false)}>
                  <XIcon className="h-3 w-3 text-white" />
                </button>
              )}
            </div>
            <p>
              Use AI filters to help you with the search if you do not know where to find the right
              effects, attributes, and similar.
            </p>
          </div>
        }
        position="bottom"
      >
        <button
          onClick={() => setOpened(true)}
          className="rounded-md bg-white p-2"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <SparklesIcon className="h-5 w-5 text-primary-900 " />
        </button>
      </Tooltip>
    </>
  )
}
