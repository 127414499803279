import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const ScrollToTop: React.FunctionComponent<React.PropsWithChildren<unknown>> = (props) => {
  const { pathname } = useLocation()

  useEffect(() => {
    const mainElement = document.getElementById('main')
    const outletConatiner = document.getElementById('scrollable')
    if (mainElement) mainElement?.scroll(0, 0)

    if (outletConatiner) outletConatiner?.scroll(0, 0)
  }, [pathname])

  return null
}
