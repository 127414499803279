import React, { useEffect, useMemo, useState } from 'react'
import { PlayIcon } from '@heroicons/react/outline'
import { Button, Modal, Tooltip } from '@mantine/core'
import { QueryBuilderDnD } from '@react-querybuilder/dnd'
import { atom, useAtom, useSetAtom } from 'jotai'
import { XIcon } from 'lucide-react'
import * as ReactDnD from 'react-dnd'
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend'
import {
  defaultOperators,
  defaultValidator,
  Field,
  formatQuery,
  QueryBuilder,
  RuleGroupType,
  RuleValidator,
  ValidationResult,
  ValueEditorProps,
} from 'react-querybuilder'
import { SearchMd } from 'untitled-icons'

import { VideoModal } from '@/components/VideoModal'
import WholeWord from '@/icons/WholeWord'
import { MantineValueEditor, QueryBuilderMantine } from '@/lib/react-querybuilder-mantine'
import { classNames } from '@/util/classNames'
import { settingsMenuAtom } from '../../_components/SettingsMenu'
import { useTrackedSettings } from '../../_hooks/useGlobalSettings'
import { useMIStore } from '../_hook'

const validator: RuleValidator = (q): ValidationResult => {
  return {
    valid: !!q.value || q.value.length > 0,
    reasons: ['this field cannot be empty'],
  }
}

export const advancedSearchFields: Field[] = [
  {
    name: 'q_product_text',
    label: 'Product Information',
  },
  {
    name: 'q_ing',
    label: 'Ingredients',
  },
].map((field) => ({
  ...field,
  operators: defaultOperators.filter((op) => ['contains', 'doesNotContain'].includes(op.name)),
  validator,
  datatype: 'matchPhrase',
}))

export const advancedModeIndicator = atom(false)

export const CustomValueEditor = (props: ValueEditorProps) => {
  const { matchPhrase, toggleMatchPhrase } = useMIStore()

  if (props.fieldData.datatype === 'matchPhrase') {
    return (
      <div className="relative">
        <Tooltip label="Match Exact Phrase">
          <button
            className={classNames(
              'absolute right-2 top-2 z-10 p-0.5',
              matchPhrase.includes(props.field)
                ? ' rounded-sm bg-gray-100 ring-1 ring-black ring-offset-2'
                : ''
            )}
            onClick={() => {
              console.log({ matchPhrase, field: props.field })
              toggleMatchPhrase(props.field)
            }}
          >
            <WholeWord className="size-4" />
          </button>
        </Tooltip>
        <MantineValueEditor {...props} />
      </div>
    )
  }
  return <MantineValueEditor {...props} />
}

export const AdvancedSearch = () => {
  const [showNote, setshowNote] = useState(true)
  const [opened, setOpened] = useState(false)
  const { advancedSearch, setAdvancedSearch } = useMIStore()
  const [query, setQuery] = useState<RuleGroupType>({
    combinator: 'or',
    rules: [
      { field: 'q_product_text', operator: 'contains', value: '' },
      { field: 'q_ing', operator: 'contains', value: '' },
    ],
  })

  const [showIndicator, setShowIndicator] = useAtom(advancedModeIndicator)

  useEffect(() => {
    if (showIndicator) {
      setTimeout(() => {
        setShowIndicator(false)
      }, 5000)
    }
  }, [setShowIndicator, showIndicator])

  useEffect(() => {
    if (advancedSearch) {
      setQuery(advancedSearch)
    }
  }, [])

  const expression = useMemo(
    () =>
      formatQuery(query, {
        format: 'sql',
        fields: advancedSearchFields,
      }),
    [query]
  )

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false)
        }}
        size={1000}
        centered
        withCloseButton={false}
        className="relative"
        // p={0}
      >
        <button className="absolute right-8 top-8" onClick={() => setOpened(false)}>
          <XIcon className="size-5 text-primary-500" />{' '}
        </button>
        <div className="space-y-6 p-3 text-left">
          <div className="space-y-2">
            <h1 className="text-lg font-semibold">Advanced Search</h1>
            <VideoModal
              href="https://veomzntynruzalwfhfmg.supabase.co/storage/v1/object/public/video-tutorials/advanced_search.mp4?t=2024-09-30T14%3A42%3A01.856Z"
              className="flex items-center space-x-1.5 text-sm text-accent-600 hover:underline"
            >
              <PlayIcon className="size-5" />{' '}
              <p>Click here to watch video tutorial to help you understand this feature.</p>
            </VideoModal>
          </div>
          {showNote && (
            <div className="flex items-center justify-between rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-primary-500">
              <div className="flex max-w-lg items-center space-x-3 text-xs">
                <WholeWord className="size-5 shrink-0 text-primary-900" />
                <p>
                  When you activate "match exact phrase," the search will{' '}
                  <span className="font-semibold">only</span> show results that{' '}
                  <span className="font-semibold">exactly</span> match your word.
                </p>
              </div>
              <button
                onClick={() => {
                  setshowNote(false)
                }}
              >
                <XIcon className="size-4" />
              </button>
            </div>
          )}
          <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
            <QueryBuilderMantine>
              <QueryBuilder
                fields={advancedSearchFields}
                query={query}
                onQueryChange={setQuery}
                controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
                validator={defaultValidator}
                controlElements={{ valueEditor: CustomValueEditor }}
              />
            </QueryBuilderMantine>
          </QueryBuilderDnD>
          <Button
            color="gray"
            disabled={query.rules.length === 0 || expression === '()'}
            onClick={() => {
              setAdvancedSearch(query)
              setOpened(false)
            }}
          >
            Search
          </Button>
        </div>
      </Modal>
      <Button
        variant="outline"
        color="gray"
        fullWidth
        onClick={() => {
          setOpened(true)
          setShowIndicator(false)
        }}
        className="relative rounded-md !border-gray-200 !bg-gray-50 p-2 hover:!bg-gray-100"
      >
        <div className="relative mx-4 flex items-center">
          {showIndicator && <div className="pulsating-dot absolute -right-1 -top-1"></div>}
          <SearchMd className="mr-1 size-4" />
          <p> Advanced Search</p>
        </div>
      </Button>
    </>
  )
}

export const ActivateAdvancedSearch = () => {
  const { setAdvancedMode } = useTrackedSettings()
  const setShowIndicator = useSetAtom(advancedModeIndicator)
  const setShowSettingsMenu = useSetAtom(settingsMenuAtom)
  return (
    <div className="text-xs text-gray-700">
      For a more tailored search experience, activate{' '}
      <button
        className="inline-flex items-center space-x-0.5 border-b-2 border-gray-700 font-semibold hover:border-accent-600 hover:text-accent-600"
        style={{ verticalAlign: 'top' }}
        onClick={() => {
          setShowIndicator(true)
          setAdvancedMode(true)
          setShowSettingsMenu(true)
        }}
      >
        <SearchMd className="size-[13px] [&_path]:stroke-[3]" /> <p>Advanced Search</p>
      </button>{' '}
      to refine your results and find exactly what you need.
    </div>
  )
}
