import React, { forwardRef, useEffect, useState } from 'react'
import {
  CheckIcon,
  ChevronDownIcon,
  ExclamationIcon,
  InformationCircleIcon,
  TrashIcon,
  XIcon,
} from '@heroicons/react/outline'
import { Button, HoverCard, Modal, Popover, TextInput } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import { groupBy, uniq, xorBy } from 'lodash'
import { getUntrackedObject } from 'react-tracked'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { z } from 'zod'

import { Loader } from '@/components/Loader'
import { classNames } from '@/util/classNames'
import { Countries } from '@/util/getCurrency'
import { trpc } from '@/util/trpc'
import { FILTERS_MAP, FilterType, useTrackedMIStore } from '../_hook'
import { FilterChip } from './Filters'

const schema = z.object({
  name: z.string().min(2, { message: 'Name should have at least 2 letters' }),
})

export const SaveTemplatesModal = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<{ opened: boolean; setOpened: (v: boolean) => void }>
>(({ opened, setOpened }, ref) => {
  const { filter_ids, c2Id, geo } = useTrackedMIStore()
  const [filterIds, setFilterIds] = useState<FilterType[]>([])
  const utils = trpc.useContext()
  const { mutate, isLoading } = trpc.template.saveTemplate.useMutation({
    onSuccess: () => {
      setOpened(false)
      notifications.show({
        title: 'Success!',
        message: 'Your template has been saved successfully.',
        color: 'green',
        icon: <CheckIcon className="h-4 w-4" />,
      })
      utils.template.getTemplates.invalidate({ c2Id })
    },
  })
  const form = useForm({
    validate: zodResolver(schema),
    initialValues: {
      name: '',
    },
  })
  useEffect(() => {
    setFilterIds(getUntrackedObject(filter_ids) ?? [])
  }, [filter_ids, opened])

  if (filter_ids.length === 0) return null

  return (
    <Modal
      opened={opened}
      onClose={() => {
        setOpened(false)
      }}
      size="lg"
      title={<h1 className="text-lg font-medium">Save Your Filters</h1>}
      centered
      withCloseButton={false}
    >
      <form
        className="space-y-4"
        onSubmit={form.onSubmit((values) =>
          mutate({ c2Id, filterIds, name: values.name, country: geo })
        )}
      >
        <p className="text-sm text-primary-500">
          Pre-save filter settings to avoid selecting them repeatedly during searches.
        </p>
        <div className="-m-1 flex flex-wrap items-center">
          {filterIds.map((d) => (
            <FilterChip
              key={d.id}
              onClose={() => {
                setFilterIds(xorBy(filterIds, [d], (o) => o.id))
              }}
            >
              {d.name}
            </FilterChip>
          ))}
        </div>
        <p className="!mt-10 text-sm text-primary-900">Name your filters</p>
        <TextInput
          {...form.getInputProps('name')}
          data-autofocus
          placeholder="e.g. Natural Filters..."
        />
        <div className="flex w-full justify-end">
          <Button type="submit" loading={isLoading}>
            Save Your Filters
          </Button>
        </div>
      </form>
    </Modal>
  )
})

SaveTemplatesModal.displayName = 'SaveTemplates'

export const SelectTemplates = () => {
  const { c2Id, setFilters, filter_ids, geo } = useTrackedMIStore()
  const { data: templates, isLoading } = trpc.template.getTemplates.useQuery({
    c2Id,
  })
  const [templateId, setTemplateId] = useState('')
  useDeepCompareEffect(() => {
    const currentTemplate = templates?.find((t) => t.id === templateId)
    if (!currentTemplate) return
    const templateFilterIds = currentTemplate?.filterIds as FilterType[]
    const isEqual =
      xorBy(templateFilterIds, filter_ids, (o) => o?.id).length === 0 &&
      geo === currentTemplate.country
    if (isEqual) return
    setTemplateId('')
  }, [templateId, getUntrackedObject(filter_ids), geo])
  return (
    <SelectMenu
      value={templateId}
      onChange={(id) => {
        setTemplateId(id)
        const currentTemplate = templates?.find((t) => t.id === id)
        if (!currentTemplate) return
        const filter_ids = currentTemplate?.filterIds as FilterType[]
        setFilters((state) => {
          state.filter_ids = filter_ids
          state.geo = currentTemplate.country as Countries
        })
      }}
      placeholder="Your Filters"
      isLoading={isLoading}
      options={templates?.map((t) => ({ label: t.name, value: t.id, ...t })) ?? []}
    />
  )
}

interface Option {
  label: string
  value: string
  disabled?: boolean
  [x: string]: any
}

type Props<T = string> = {
  options: Option[]
  value?: T
  placeholder?: string
  onChange?: (value: T) => void
  disabled?: boolean
  isLoading?: boolean
}

export const SelectMenu = ({
  placeholder,
  value = '',
  disabled,
  isLoading,
  onChange,
  options,
}: Props) => {
  const labelOption = options.find((o) => o.value === value)?.label
  const [dropdownOpened, setDropdownOpened] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [hoverId, setHoverId] = useState('')

  if (isLoading) disabled = true

  return (
    <div className="w-full">
      <Popover
        shadow="md"
        width={'target'}
        position="bottom-start"
        opened={dropdownOpened}
        onChange={setDropdownOpened}
        zIndex={200}
      >
        <Popover.Target>
          <button
            className={classNames(
              'input w-full truncate rounded-md bg-white px-3 py-2 text-left text-sm',
              disabled && 'pointer-events-none border-gray-200 text-gray-500'
            )}
            onClick={() => setDropdownOpened((o) => !o)}
          >
            {placeholder ?? labelOption}
            {!disabled && (
              <ChevronDownIcon className="float-right mt-[1px] h-4 w-4 text-gray-600" />
            )}
            {isLoading && <Loader className="float-right mt-[1px] h-4 w-4 border-2" />}
          </button>
        </Popover.Target>
        <Popover.Dropdown className="scroller-sm space-y-3 text-left text-sm font-normal" p={4}>
          <div className="max-h-64 space-y-1 overflow-y-auto">
            {options.length === 0 ? (
              <div className="flex min-h-[256px] flex-col justify-between p-3">
                <p className="text-xs text-primary-500">
                  No saved filters found. Watch tutorial to learn how to save them.
                </p>
                <Modal
                  opened={modalOpen}
                  onClose={() => setModalOpen(false)}
                  size="800px"
                  padding={0}
                  withCloseButton={false}
                  centered
                  withinPortal
                >
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video width={800} height="auto" controls>
                    <source
                      src="https://eyva-reports.s3.eu-central-1.amazonaws.com/save+filter.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </Modal>
                <button
                  onClick={() => {
                    setModalOpen(true)
                  }}
                  className="text-left text-xs font-medium text-accent-600 hover:text-accent-800"
                >
                  Click here for tutorial
                </button>
              </div>
            ) : (
              options.map((option, id) => {
                const isSelected = value === option.value
                const data = groupBy(option.filterIds, (o) => FILTERS_MAP[o.type])

                return (
                  <button
                    type="button"
                    key={id}
                    className={classNames(
                      'group relative flex w-full items-center rounded-md py-2 pl-8 pr-4 hover:bg-gray-50 ',
                      isSelected && 'bg-gray-50 font-medium',
                      option.disabled && 'pointer-events-none text-gray-500'
                    )}
                    disabled={disabled || option.disabled}
                    onClick={(e) => {
                      e.preventDefault()
                      onChange?.(option.value)
                      setDropdownOpened(false)
                    }}
                    onMouseEnter={() => setHoverId(option.id)}
                    onMouseLeave={() => setHoverId('')}
                  >
                    {isSelected && (
                      <div className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <CheckIcon className="h-4 w-4 text-accent-600" />
                      </div>
                    )}
                    <div className={'flex w-full items-center justify-between space-x-2 text-xs'}>
                      <div className="truncate">{option?.label}</div>
                      {hoverId === option.id && (
                        <div className=" flex items-center space-x-1">
                          <HoverCard
                            shadow="md"
                            withinPortal={true}
                            width={500}
                            position="bottom-start"
                            closeDelay={10}
                          >
                            <HoverCard.Target>
                              <InformationCircleIcon className="h-4 w-4" />
                            </HoverCard.Target>
                            <HoverCard.Dropdown className="space-y-4 text-left text-sm">
                              {uniq(Object.values(FILTERS_MAP)).map((filterType) => {
                                const filters = data?.[filterType] ?? []
                                // create object with count of every item in the filters
                                const count = filters.reduce((acc, curr) => {
                                  acc[curr.name] = (acc[curr.name] || 0) + 1
                                  return acc
                                }, {} as Record<string, number>)
                                if (filters.length > 0)
                                  if (filters.length > 0)
                                    return (
                                      <div key={filterType} className="space-y-2">
                                        <h2 className="text-xs font-medium capitalize">
                                          {filterType}
                                        </h2>
                                        <div className="-m-1 flex flex-wrap items-center">
                                          {filters.map((d) => (
                                            <FilterChip key={d.id}>
                                              {d.name}{' '}
                                              {count[d.name] > 1 && (
                                                <span className="font-medium text-primary-500">
                                                  ({d.parentName})
                                                </span>
                                              )}
                                            </FilterChip>
                                          ))}
                                        </div>
                                      </div>
                                    )
                              })}
                            </HoverCard.Dropdown>
                          </HoverCard>
                          <ConfirmationModal id={option.id} />
                        </div>
                      )}
                    </div>
                  </button>
                )
              })
            )}
          </div>
        </Popover.Dropdown>
      </Popover>
    </div>
  )
}

export const ConfirmationModal = ({ id }: { id: string }) => {
  const [opened, setOpened] = useState(false)

  const { c2Id } = useTrackedMIStore()
  const utils = trpc.useContext()
  const { mutate, isLoading: isDeleting } = trpc.template.deleteTemplate.useMutation({
    onSuccess: () => {
      notifications.show({
        title: 'Success!',
        message: 'Your template has been deleted successfully.',
        color: 'green',
        icon: <CheckIcon className="h-4 w-4" />,
      })
      utils.template.getTemplates.invalidate({ c2Id })
    },
  })
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false)
        }}
        size={444}
        centered
        withCloseButton={false}
        className="relative"
        p={0}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <button
          className="absolute right-8 top-8"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setOpened(false)
          }}
        >
          <XIcon className="h-5 w-5 text-primary-500" />
        </button>
        <div className="mx-auto flex min-h-[350px] max-w-[80%] flex-col justify-center space-y-4 text-center">
          <div className="mx-auto flex items-center justify-center space-x-1 text-center">
            <ExclamationIcon className=" h-12 w-12 rounded-full border-[6px] border-red-50 bg-red-100 p-1 text-red-700" />
          </div>
          <h1 className="text-base font-semibold">
            Are you sure you <br /> want to delete your saved filters?
          </h1>
          <p className="text-sm text-primary-500">This action can&apos;t be undone!</p>
          <div className="grid grid-cols-2 gap-2">
            <Button
              variant="default"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setOpened(false)
              }}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                mutate({ id })
                setOpened(false)
              }}
              fullWidth
            >
              Delete Filters
            </Button>
          </div>
        </div>
      </Modal>
      <button
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setOpened(true)
        }}
        disabled={isDeleting}
      >
        {isDeleting ? <Loader className="h-4 w-4 border-2 " /> : <TrashIcon className="h-4 w-4" />}
      </button>
    </>
  )
}
