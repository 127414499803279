import React, { createContext, useContext, useEffect, useState } from 'react'
import { uniq } from 'lodash'
import { useLocation } from 'react-router-dom'

const LocationHistoryContext = createContext<string[]>([])

export const useLocationHistory = () => useContext(LocationHistoryContext)

export const LocationHistoryProvider: React.FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [locationHistory, setLocationHistory] = useState<string[]>(() => {
    const savedHistory = localStorage.getItem('route')
    return savedHistory ? JSON.parse(savedHistory) : []
  })

  const location = useLocation()

  useEffect(() => {
    const path = location.pathname + location.search
    setLocationHistory((prev) => {
      const newHistory = uniq([path, ...prev]).slice(0, 5)
      localStorage.setItem('route', JSON.stringify(newHistory))
      return newHistory
    })
  }, [location.pathname])

  return (
    <LocationHistoryContext.Provider value={locationHistory}>
      {children}
    </LocationHistoryContext.Provider>
  )
}
