import { XIcon } from 'lucide-react'
import {
  getCompatContextProvider,
  type ControlElementsProp,
  type FullField,
} from 'react-querybuilder'

import { MantineActionElement } from './MantineActionElement'
import { MantineNotToggle } from './MantineNotToggle'
import { MantineShiftActions } from './MantineShiftActions'
import { MantineValueEditor } from './MantineValueEditor'
import { MantineValueSelector } from './MantineValueSelector'

export * from './MantineActionElement'
export * from './MantineNotToggle'
export * from './MantineShiftActions'
export * from './MantineValueEditor'
export * from './MantineValueSelector'

export const mantineControlElements = {
  removeGroupAction: (props) => (
    <MantineActionElement {...props} label={<XIcon className="size-4" />} className="!px-3" />
  ),
  removeRuleAction: (props) => (
    <MantineActionElement {...props} label={<XIcon className="size-4" />} className="!px-3" />
  ),
  actionElement: MantineActionElement,
  notToggle: MantineNotToggle,
  shiftActions: MantineShiftActions,
  valueEditor: MantineValueEditor,
  valueSelector: MantineValueSelector,
} satisfies ControlElementsProp<FullField, string>

export const QueryBuilderMantine = getCompatContextProvider({
  controlElements: mantineControlElements,
})
