import './wdyr'
import 'react-tabs-scrollable/dist/rts.css'
import 'react-querybuilder/dist/query-builder.css'
import 'swiper/css'
import 'swiper/css/pagination'
import './index.css'
import './rts.css'
import '@mantine/core/styles.css'
import '@mantine/notifications/styles.css'
import '@mantine/dates/styles.css'
import React from 'react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { createRoot } from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'

import App from './App'
import { ErrorFallbackChunk } from './components/ErrorFallback'
import { IS_PROD } from './constants'

// Please make sure to call this method only once!

Sentry.init(
  IS_PROD
    ? {
        dsn: 'https://f8bb0c5372034d7798011a45e4da0ace@o4504317388849152.ingest.sentry.io/4504321156710400',
        integrations: [
          new BrowserTracing(),
          new Sentry.Replay({ maskAllText: false, blockAllMedia: false, maskAllInputs: false }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.8,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
      }
    : {
        dsn: 'https://05b633c428c3d2f0048de9af8cb5489d@o4504317388849152.ingest.sentry.io/4506398491082752',
        integrations: [
          new BrowserTracing(),
          new Sentry.Replay({ maskAllText: false, blockAllMedia: false, maskAllInputs: false }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 1.0,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
      }
)
const root = createRoot(document.getElementById('root')!)

root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallbackChunk}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
