import React from 'react'
import { XIcon } from '@heroicons/react/outline'
import { notifications } from '@mantine/notifications'

export const notifyError = (error) => {
  notifications.show({
    title: 'Error',
    message: error?.message || 'Something went wrong',
    color: 'red',
    icon: <XIcon className="h-4 w-4" />,
  })
}
