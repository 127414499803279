import type { ComboboxData } from '@mantine/core'
import {
  isOptionGroupArray,
  parseNumber,
  uniqOptList,
  type FullOption,
  type FullOptionList,
} from 'react-querybuilder'

export const optionListToComboboxData = (list: FullOptionList<FullOption>): ComboboxData => {
  const uniqList = uniqOptList(list)
  return isOptionGroupArray(uniqList)
    ? uniqList.map((og) => ({ ...og, group: og.label, items: og.options }))
    : uniqList.map((opt) => ({ name: opt.name, value: opt.name, label: opt.label }))
}

export const toNumberInputValue = (val: number | string) => {
  if (typeof val === 'number') return val
  const valParsed = parseNumber(val, { parseNumbers: 'native' })
  if (!isNaN(valParsed)) return valParsed
  return ''
}
